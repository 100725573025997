const COOKIE_KEY_ALREADY_JUDGED_BOT = 'detectb'

export default async ({ store, $sentry, $cookies }) => {
  const cookieKeyAlreadyJudgedBot = $cookies.get(COOKIE_KEY_ALREADY_JUDGED_BOT)

  if (cookieKeyAlreadyJudgedBot) return
  if (!store.state.auth.ccuid) return
  if (store.getters['auth/isFromCoconalaApp']) return

  const { load } = await import('@fingerprintjs/botd')

  // monitoring: falseにすると、使用状況の統計を収集されるのをオフにできる https://github.com/fingerprintjs/BotD/blob/main/docs/api.md#webpackrollupnpmyarn
  const botdPromise = load({ monitoring: false })
  botdPromise
    .then(async botd => ({
      ...(await botd.collect()),
      ...botd.detect()
    }))
    .then(async result => {
      const [{ GrpcError, GRPC_ERROR_CODES }, { LogImpressionService }] =
        await Promise.all([
          import('~/api/accessors/errors/grpc-error'),
          import('~/stub/apigateway/big_query/log_impression_pb_service')
        ])

      const detail = {
        is_bot: result.bot,
        ...result
      }
      delete detail.bot

      const view_id = store.state.big_query.viewId
      const params = {
        action: 'view_new_visit',
        detailJson: JSON.stringify({ view_id, ...detail })
      }
      const res = await store.dispatch('big_query/log_impression/addLogImpression', params)

      if (res) {
        $cookies.set(
          COOKIE_KEY_ALREADY_JUDGED_BOT,
          true,
          {
            path: '/',
            maxAge: 60 * 60 * 24 * 365 * 10 // 10年
          }
        )
      } else {
        throw new GrpcError(
          LogImpressionService.AddImpressionLog,
          'failed to execute addImpressionLog after botdPromise',
          GRPC_ERROR_CODES.UNKNOWN
        )
      }
    })
    .catch(error => {
      $sentry.withScope(scope => {
        scope.setTag('detect-bot', 'failed botdPromise or sending ImpressionLog')
        $sentry.captureException(error)
      })
    })
}
